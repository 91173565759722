import create from 'zustand';
import { IFindStorePage, IStoreDetail } from '$models';

type ImageCollection = Pick<
    IFindStorePage,
    'clickCollectActive' | 'clickCollectInactive' | 'markerActive' | 'markerStandard'
>;

type StoreMapState = {
    imageCollection: ImageCollection | null;
    updateImageCollection: (model: IFindStorePage | IStoreDetail) => void;
};

export const useStoreMap = create<StoreMapState>((set) => ({
    imageCollection: null,
    updateImageCollection: (model: IFindStorePage) => {
        const { clickCollectActive, clickCollectInactive, markerActive, markerStandard } = model;

        set((state) => ({
            ...state,
            imageCollection: { clickCollectActive, clickCollectInactive, markerActive, markerStandard },
        }));
    },
}));
